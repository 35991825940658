import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Navbar from "../components/Navbar";
import headIllustration from "../assets/svgs/illustration.svg";
import Footer from "../components/Footer";
import { CiTwitter } from "react-icons/ci";
import {
  AiOutlineArrowRight,
  AiOutlineCheck,
  AiOutlineInstagram,
} from "react-icons/ai";
import Star from "../assets/svgs/star.svg";
import FirstIllustration from "../assets/svgs/firstIllustration.svg";
import SecondIllustration from "../assets/svgs/about_us_second_illustration.svg";
import BackgroungDesign from "../assets/svgs/background_design.svg";
import ArrowDown from "../assets/svgs/arrowDown.svg";
import { fetchUsersSpecificFeed } from "../ApiManager";

const AboutUs = () => {
  const [checked, setChecked] = useState(true);

  const [successStatus, setSuccessStatus] = useState(0);
  const [email, setEmail] = useState("");

  function handleInputChange(event) {
    setEmail(event.target.value);
    console.log(event.target.value);
  }
  const handleChange = () => {
    setChecked(!checked);
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(validRegex)) {
      fetchUsersSpecificFeed(email, checked).then((res) => {
        if (res.error === false) {
          setSuccessStatus(1);
        } else {
          alert(res.message);
        }
      });
    } else {
      alert("Please enter valid email address");
    }
  };

  return (
    <>
      <Navbar backgroundColor="#EC2872" />
      <div className=" flex-col flex  bg-[#EC2872] rounded-b-[40px]">
        <div className="flex flex-row relative">
          {/* <div className="flex self-end absolute  h-[60%] md:h-[90%] w-[50%] bg-white lg:ml-[13%] md:ml-[5%] z-0 overflow">
            <img src={Human} className="object-cover" />
          </div> */}
          <div className=" w-[100%] flex flex-col items-center justify-center py-[15%] md:py-[10%] ">
            <h1 className="text-[#fff] text-3xl md:text-5xl lg:text-6xl text-center  font-medium font-roboto_black 	">
              Do You Have Questions?
              <br /> We Have Answers.
            </h1>
            <h5 className="font-light text-sm mt-2 md:text-xl  font-lato_regular text-white">
              And so do your friends.
            </h5>

            <img
              src={Star}
              className="w-[15%]  self-end absolute bottom-[10%] right-[4%]"
            />
            <img
              src={Star}
              className="w-[15%]  self-end absolute top-[5%] left-[20%]"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row lg:px-[10%] ">
        <div className="flex flex-1 h-[220px] md:h-[400px] relative my-[5%] justify-center ">
          <img src={FirstIllustration} className="absolute h-[100%]  " />
        </div>
        <div className="flex flex-1 flex-col justify-center mr-[3%] md:mr-[0%]  ">
          <h1 className="md:text-5xl text-3xl font-medium font-roboto_black text-[#940DFF] mb-[2%]">
            Welcome to Recozilla
          </h1>
          <p className="md:mr-[30%] font-light text-[13px] mt-2 md:text-xl  font-lato_regular">
            The social Q&A app that lets you ask anything and get answers from
            anyone. Whether it's trivia, advice, opinions, or facts, Recozilla
            has it all.
          </p>
        </div>
      </div>
      <div className="mx-[5%] lg:px-[15%] mb-[5%]">
        <p className="my-2 font-lato_regular text-md ">
          Recozilla is not just a Q&A app. It's a community of curious and
          passionate people who love to share their knowledge and views on any
          topic. You can follow your favorite topics and people, vote for the
          best answers, and discover new things every day. 🚀
        </p>
        <p className="my-2 font-lato_regular text-md ">
          Recozilla is easy to use and fun to explore. Just download the app and
          sign up with your email or social media account and start asking and
          answering questions.
        </p>
      </div>

      {/* Fourth Section */}
      <div className="flex flex-row relative lg:px-[20%]">
        <img
          src={BackgroungDesign}
          className="-z-20 absolute object-cover top-0 bottom-0 left-0 right-0 h-full w-full"
        />
        <div className="flex w-44 h-[220px] -mr-[5%] md:mr-[10%] md:h-[400px] w-[300px]  relative  ">
          <img
            src={SecondIllustration}
            className="absolute h-[100%] object-cover "
          />
        </div>
        <div className="flex flex-1 flex-col justify-center mr-[3%] md:mr-[0%]  ">
          <p className=" font-roboto_black md:text-4xl text-2xl text-white">
            Join us today and be part of the Recozilla community! 💯
          </p>
        </div>
      </div>

      {/* Fifth Section */}

      {successStatus ? (
        <div className=" px-1 flex w-full justify-between  md:justify-around items-center relative bg-[#EC2872] ">
          <div className="h-52 md:h-80 justify-center items-center flex">
            <h1 className="text-white text-2xl  md:text-4xl font-roboto_black text-center">
              Thank you. We will let you know when you have access to our app
            </h1>
            <img
              src={Star}
              className="w-[10%]  self-end absolute bottom-[10%] right-[4%]"
            />
            <img
              src={Star}
              className="w-[5%]  self-end absolute top-[5%] left-[20%]"
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-1 flex-row md:h-80 h-52 items-center mx-[5%] lg:px-[15%]">
          <div className="flex-1 bg-white ">
            <h1 className="md:text-5xl text-2xl font-medium font-roboto_black  mb-[2%]">
              Sign up for Early Access
            </h1>
          </div>
          <div className="flex-1">
            <div className="flex  w-full items-center bg-black py-2 md:py-4 px-4 rounded-full">
              <form className="w-full">
                <input
                  type="text"
                  // onKeyDown={handleKeyDown}
                  value={email}
                  onChange={handleInputChange}
                  placeholder="Enter your email id"
                  className="w-full outline-none bg-black text-sm text-white outline-0 font-lato_regular pr-[20px]"
                />
              </form>
              <AiOutlineArrowRight
                onClick={handleEmailSubmit}
                className="cursor-pointer"
                color="#fff"
                size={20}
              />
            </div>
            <div className="flex pt-2 items-center ml-[18px]">
              <div
                onClick={handleChange}
                className="w-4 h-4 mr-2 border rounded-sm border-2 flex justify-center items-center border-black cursor-pointe"
              >
                {checked ? <AiOutlineCheck color="#111" size={20} /> : null}
              </div>
              <p className="ml-1 text-[10px] md:text-[14px]">
                Signup for the newsletter
              </p>
            </div>
          </div>
        </div>
      )}

      {/* <div className=" px-1 flex w-full justify-between  md:justify-around items-center relative bg-[#EC2872] ">
        <div className="overflow-hidden">
          <img
            src={SecondIllustration}
            className="w-[25%] ml-[4%]  md:w-[200px] md:ml-[0%]  lg:ml-[11%]  absolute bottom-0"
          />
        </div>
        <div className=" w-[75%] md:w-[60%] lg:w-[40%] p-10 md:p-20  self-center  lg:mr-[10%] md:mr-[0]">
          <h1 className="text-center text-sm text-white font-roboto_black  mb-2 md:text-2xl lg:text-3xl md:pb-[10px]">
            Sign up for Early Access
          </h1>
          <div className="flex  w-full items-center bg-black py-2 md:py-4 px-4 rounded-full"></div>
          <div className="flex pt-2 items-center ml-[18px]">
            <p className="ml-1 text-white text-[10px] md:text-[14px]">
              Signup for the Newsletter
            </p>
          </div>
        </div>
      </div> */}
      {/* <div className="">
        <div className="px-5 py-10 lg:px-[20%]">
          <h1 className="font-lato_bold text-[30px] md:text-3xl mb-2 text-[#940DFF]">
            About us
          </h1>
          <p className="my-2 font-lato_regular text-md">
            Do you have questions? We have answers. And so do your friends. 🙋‍♂️🙋‍♀️
          </p>

          <p className="my-2 font-lato_regular text-md">
            Welcome to Recozilla, the social Q&A app that lets you ask anything
            and get answers from anyone. Whether it's trivia, advice, opinions,
            or facts, Recozilla has it all. 🙌
          </p>

          <p className="my-2 font-lato_regular  text-md">
            Recozilla is not just a Q&A app. It's a community of curious and
            passionate people who love to share their knowledge and views on any
            topic. You can follow your favorite topics and people, vote for the
            best answers, and discover new things every day. 🚀
          </p>

          <p className="my-2 font-lato_regular  text-md">
            Recozilla is easy to use and fun to explore. Just download the app
            and sign up with your email or social media account and start asking
            and answering questions. 😎
          </p>
          <p className="my-2 font-lato_regular  text-md">
            Join us today and be part of the Recozilla community! 💯
          </p>
          <p className="font-lato_regular  text-md pt-3">Sincerely,</p>
          <p className=" font-lato_bold text-md ">Team Recozilla</p>
        </div>
      </div> */}
      <Footer />
    </>
  );
};

export default AboutUs;
