export const fetchUsersSpecificFeed = async (email, checked) => {
  // console.log(email);
  // console.log(checked);
  let formdata = new FormData();
  formdata.append("email", email);
  formdata.append("newsletter", checked ? 1 : 0);

  try {
    const res = await fetch(
      "https://dev.recozilla.com/wp-json/rz/v1/early_access_api",
      {
        method: "POST",
        body: formdata,
        dataType: "JSON",
      }
    );

    const result = await res?.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

// const fetchUsersSpecificFeed = async (email) => {
//     // console.log(email);
//     // console.log(checked);
//     let formdata = new FormData();
//     formdata.append("email", email);
//     formdata.append("newsletter", checked ? 1 : 0);

//     try {
//       const res = await fetch(
//         "https://recozilla.com/wp-json/rz/v1/early_access_api",
//         {
//           method: "POST",
//           body: formdata,
//           dataType: "JSON",
//         }
//       );

//       const result = await res?.json();
//       return result;
//     } catch (error) {
//       console.log(error);
//     }
//   };
