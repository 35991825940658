import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const ListItems = [
  {
    bold: "Explicitly attribute the source.",
    normal:
      "If the source is online, you should include a link to the page where the content can be found. If it is a physical source (e.g., a book), you should include a citation.",
  },
  {
    bold: "Clearly indicate which portions of the text are copied from another source.",
    normal:
      "Format using blockquotes, quotes, or otherwise visually indicate which portions are taken from elsewhere",
  },
  {
    bold: "Use only small amount of text from other source.",
    normal:
      "Do not take more than couple of lines from other sources. Even in those cases, attribute them properly.",
  },
];

const Terms = () => {
  return (
    <>
      <Navbar />
      <div className="">
        <div className="px-5 py-10 lg:px-[20%]">
          <h1 className="font-lato_bold text-[30px] md:text-3xl mb-2 text-[#940DFF]">
            Terms & Conditions
          </h1>

          <p className="font-lato_light">Last updated: November 10, 2021</p>

          <p className="my-2 font-lato_regular text-md ">
            Please read these Terms of Use (“Terms”, “Terms of Use”) carefully
            before using the https://recozilla.com website (the “Service”)
            operated by Recozilla (“us”, “we”, or “our”).
          </p>

          <p className="my-2 font-lato_regular text-md ">
            Your access to and use of the Service is conditioned on your
            acceptance of and compliance with these Terms. These Terms apply to
            all visitors, users and others who access or use the Service.
          </p>

          <p className="my-2 font-lato_regular text-md ">
            By accessing or using the Service you agree to be bound by these
            Terms. If you disagree with any part of the terms then you may not
            access the Service.
          </p>

          <h2 className="font-lato_bold text-xl mt-4">Account</h2>

          <p className="my-2 font-lato_regular text-md ">
            When you create an account with us, you must provide us information
            that is accurate, complete, and current at all times. Failure to do
            so constitutes a breach of the Terms, which may result in immediate
            termination of your account on our Service.
          </p>
          <p className="my-2 font-lato_regular text-md ">
            We need you to provide your real name at the time of account
            creation and keep it same at all times. We do not allow pseudo
            names, nick names or bot names. Failure to keep your profile name
            real may lead to suspension or deletion of your account
          </p>
          <p className="my-2 font-lato_regular text-md ">
            You are responsible for safeguarding the password that you use to
            access the Service and for any activities or actions under your
            password, whether your password is with our Service or a third-party
            service.
          </p>
          <p className="my-2 font-lato_regular text-md ">
            You agree not to disclose your password to any third party. You must
            notify us immediately upon becoming aware of any breach of security
            or unauthorized use of your account.
          </p>

          {/* <div className="pl-4">
            {ListItems.map((item) => (
              <p className="my-2 font-lato_regular text-md ">{`\u2022 ${item}`}</p>
            ))}
          </div> */}

          <h2 className="font-lato_bold text-xl mt-4">Intellectual Property</h2>
          <p className="my-2 font-lato_regular text-md ">
            The Service and its original content, features and functionality are
            and will remain the exclusive property of Recozilla and its
            licensors.
          </p>

          <h2 className="font-lato_bold text-xl mt-4">Plagiarism</h2>

          <p className="my-2 font-lato_regular text-md ">
            Answers that copy or paraphrase content from other sources should:
          </p>
          <div className="ml-4">
            <ol style={{ listStyleType: "unset" }}>
              {ListItems.map((question) => {
                return (
                  <li className="ml-2">
                    <p className="my-2 font-lato_regular text-md ">
                      <strong className="font-lato_bold">
                        {" "}
                        {question.bold}{" "}
                      </strong>
                      {question.normal}
                    </p>
                  </li>
                );
              })}
            </ol>
          </div>

          <p className="my-2 font-lato_regular text-md ">
            Republishing your own content from another site is not encouraged.
            We believe in users producing new content. You can only refer back
            to your content on other site with proper attribution.
          </p>
          <p className="my-2 font-lato_regular text-md ">
            Plagiarizing content is considered a serious policy violation on
            Recozilla and will be treated as such in considering moderation
            action against writers who violate the policy. Answers that
            plagiarize content from other sources will be removed.
          </p>

          <h2 className="font-lato_bold text-xl mt-4">
            Copyright & Trademark Infringement
          </h2>
          <p className="my-2 font-lato_regular text-md ">
            Avoid infringing copyrights or trademarks of others – be it text or
            image. If you infringe copyrights or trademarks of others, your
            content may be removed or suspended. We assume that you own full
            rights of all the content you publish. Provide appropriate
            attributions whenever applicable. Repeated infringements may lead us
            to suspend or delete your account.
          </p>

          <p className="my-2 font-lato_regular text-md ">
            If you are copyright or trademark owner for content and wish to
            report its infringement on the Service, write to us at
            support@recozilla.com with details of content. We will assist you to
            take it down upon validation of claim.
          </p>

          <h2 className="font-lato_bold text-xl mt-4">
            No Hate, Violence, Spam or Nudity
          </h2>
          <p className="my-2 font-lato_regular text-md ">
            We do not allow any content that directly or indirectly cause hate,
            promotes violence, is spam or displays nudity. Any such content may
            be removed or suspended from our platform upon detection by our
            content moderation or upon receiving a complaint about such content.
          </p>
          <p className="my-2 font-lato_regular text-md ">
            We also discourage any content that promotes, abuses, criticize or
            belittles any individual, political party, religion, nation, race,
            culture, business entity or an organization. Any such content may be
            removed from site without any notification and repeat offenders may
            be suspended or removed from site.
          </p>

          <h2 className="font-lato_bold text-xl mt-4">
            Child Sexual Abuse and Exploitation (CSAE)
          </h2>
          <p className="my-2 font-lato_regular text-md ">
            We have a strict zero-tolerance policy against child sexual abuse and exploitation. Any such content will be immediately removed, and we will report violations to the appropriate authorities, including local law enforcement agencies and child protection organizations.
          </p>

          <p className="my-2 font-lato_regular text-md ">
            Our app employs strict content moderation, AI-based detection, and manual review to prevent CSAE. Users can report suspicious content via in-built reporting mechanisms in Recozilla or send an email to support@recozilla.com. 
            We comply with Google Play/Apple App Store policies, national laws, and international regulations to ensure the safety of children on our platform.
          </p>

          <h2 className="font-lato_bold text-xl mt-4">
            Links To Other Web Sites
          </h2>

          <p className="my-2 font-lato_regular text-md ">
            Our Service may contain links to third-party web sites or services
            that are not owned or controlled by Recozilla.
          </p>
          <p className="my-2 font-lato_regular text-md ">
            Recozilla has no control over, and assumes no responsibility for,
            the content, privacy policies, or practices of any third party web
            sites or services. You further acknowledge and agree that Recozilla
            shall not be responsible or liable, directly or indirectly, for any
            damage or loss caused or alleged to be caused by or in connection
            with use of or reliance on any such content, goods or services
            available on or through any such web sites or services.
          </p>
          <p className="my-2 font-lato_regular text-md ">
            We strongly advise you to read the terms and conditions and privacy
            policies of any third-party web sites or services that you visit.
          </p>

          <h2 className="font-lato_bold text-xl mt-4">Termination</h2>
          <p className="my-2 font-lato_regular text-md ">
            We may terminate or suspend your account immediately, without prior
            notice or liability, for any reason whatsoever, including without
            limitation if you breach the Terms.
          </p>
          <p className="my-2 font-lato_regular text-md ">
            Upon termination, your right to use the Service will immediately
            cease. If you wish to terminate your account, you may simply either
            discontinue using the Service or contact our support team at
            support@recozilla.com and we will help you with your account
            deletion.
          </p>
          <p className="my-2 font-lato_regular text-md ">
            All provisions of the Terms which by their nature should survive
            termination shall survive termination, including, without
            limitation, ownership provisions, warranty disclaimers, indemnity
            and limitations of liability.
          </p>

          <h2 className="font-lato_bold text-xl mt-4">Disclaimer</h2>
          <p className="my-2 font-lato_regular text-md ">
            Your use of the Service is at your sole risk. The Service is
            provided on an “AS IS” and “AS AVAILABLE” basis. The Service is
            provided without warranties of any kind, whether express or implied,
            including, but not limited to, implied warranties of
            merchantability, fitness for a particular purpose, non-infringement
            or course of performance.
          </p>
          <p className="my-2 font-lato_regular text-md ">
            The information on this site is not intended or implied to be a
            substitute for professional medical advice, diagnosis or treatment.
          </p>
          <p className="my-2 font-lato_regular text-md ">
            The information on this site is for informational purposes only and
            should not be construed as legal, tax, investment or financial
            advice.
          </p>

          <h2 className="font-lato_bold text-xl mt-4">Governing Law</h2>
          <p className="my-2 font-lato_regular text-md ">
            These Terms shall be governed and construed in accordance with the
            laws of India without regard to its conflict of law provisions.
          </p>
          <p className="my-2 font-lato_regular text-md ">
            Our failure to enforce any right or provision of these Terms will
            not be considered a waiver of those rights. If any provision of
            these Terms is held to be invalid or unenforceable by a court, the
            remaining provisions of these Terms will remain in effect. These
            Terms constitute the entire agreement between us regarding our
            Service, and supersede and replace any prior agreements we might
            have between us regarding the Service.
          </p>

          <h2 className="font-lato_bold text-xl mt-4">Changes</h2>
          <p className="my-2 font-lato_regular text-md ">
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. If a revision is material we will try to
            provide at least 30 days notice prior to any new terms taking
            effect. What constitutes a material change will be determined at our
            sole discretion.
          </p>
          <p className="my-2 font-lato_regular text-md ">
            By continuing to access or use our Service after those revisions
            become effective, you agree to be bound by the revised terms. If you
            do not agree to the new terms, please stop using the Service.
          </p>

          <h2 className="font-lato_bold text-xl mt-4">Contact Us</h2>
          <p className="my-2 font-lato_regular text-md ">
            If you have any questions about these Terms, please contact us at
            support@recozilla.com
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
