import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const ListItems = ["Name", "Photograph", "Email address", "Telephone number"];

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <div className="">
        <div className="px-5 py-10 lg:px-[20%]">
          <h1 className="font-lato_bold text-[30px] md:text-3xl mb-2 text-[#940DFF]">
            Privacy Policy
          </h1>
          <p className="font-lato_light">Last updated: November 10, 2021</p>

          <p className="my-2 font-lato_regular text-md">
            Recozilla (“us”, “we”, or “our”) operates the Recozilla website (the
            “Service”).
          </p>

          <p className="my-2 font-lato_regular text-md">
            This page informs you of our policies regarding the collection, use
            and disclosure of Personal Information when you use our Service.
          </p>

          <p className="my-2 font-lato_regular  text-md">
            We will not use or share your information with anyone except as
            described in this Privacy Policy.
          </p>

          <p className="my-2 font-lato_regular  text-md">
            We use your Personal Information for providing and improving the
            Service. By using the Service, you agree to the collection and use
            of information in accordance with this policy. Unless otherwise
            defined in this Privacy Policy, terms used in this Privacy Policy
            have the same meanings as in our Terms and Conditions, accessible at
            https://recozilla.com
          </p>

          <h1 className="font-lato_bold text-xl mt-4">
            Information Collection And Use
          </h1>

          <p className="my-2 font-lato_regular text-md">
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you. Personally identifiable information (“Personal
            Information”) may include, but is not limited to:
          </p>

          <div className="pl-4">
            {ListItems.map((item) => (
              <p className="my-2 font-lato_regular text-md">{`\u2022 ${item}`}</p>
            ))}
          </div>

          <h1 className="font-lato_bold text-xl mt-4">
            Google AdSense & DoubleClick Cookie
          </h1>
          <p className="my-2 font-lato_regular text-md">
            Google, as a third party vendor, uses cookies to serve ads on our
            Service.
          </p>

          <h1 className="font-lato_bold text-xl mt-4">Cookies</h1>

          <p className="my-2 font-lato_regular text-md">
            Cookies are files with small amount of data, which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            web site and stored on your computer’s hard drive.
          </p>
          <p className="my-2 font-lato_regular text-md">
            We use “cookies” to collect information. You can instruct your
            browser to refuse all cookies or to indicate when a cookie is being
            sent. However, if you do not accept cookies, you may not be able to
            use some portions of our Service
          </p>

          <h1 className="font-lato_bold text-xl mt-4">Service Providers</h1>
          <p className="my-2 font-lato_regular text-md">
            We may employ third party companies and individuals to facilitate
            our Service, to provide the Service on our behalf, to perform
            Service-related services or to assist us in analyzing how our
            Service is used.
          </p>

          <p className="my-2 font-lato_regular text-md">
            These third parties have access to your Personal Information only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>

          <h1 className="font-lato_bold text-xl mt-4">Security</h1>
          <p className="my-2 font-lato_regular text-md">
            The security of your Personal Information is important to us, but
            remember that no method of transmission over the Internet, or method
            of electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your Personal Information,
            we cannot guarantee its absolute security.
          </p>
          <h1 className="font-lato_bold text-xl mt-4">Termination</h1>

          <p className="my-2 font-lato_regular text-md">
            We may terminate or suspend your account immediately, without prior
            notice or liability, for any reason whatsoever, including without
            limitation if you breach the Terms of Use.
          </p>
          <p className="my-2 font-lato_regular text-md">
            Upon termination, your right to use the Service will immediately
            cease. If you wish to terminate your account, you may simply either
            discontinue using the Service or contact our support team at
            support@recozilla.com and we will help you with your account
            deletion.
          </p>
          <p className="my-2 font-lato_regular text-md">
            To request deletion of your Personal Information, write to us at
            support@recozilla.com with your account details and we will process
            your deletion request. Please note that request for deletion of
            Personal Information will lead to termination of account as well.
          </p>
          <p className="my-2 font-lato_regular text-md">
            All provisions of the Terms of Use which by their nature should
            survive termination shall survive termination, including, without
            limitation, ownership provisions, warranty disclaimers, indemnity
            and limitations of liability.
          </p>

          <h1 className="font-lato_bold text-xl mt-4">Links To Other Sites</h1>
          <p className="my-2 font-lato_regular text-md">
            Our Service may contain links to other sites that are not operated
            by us. If you click on a third party link, you will be directed to
            that third party’s site. We strongly advise you to review the
            Privacy Policy of every site you visit.
          </p>
          <p className="my-2 font-lato_regular text-md">
            We have no control over, and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>

          <h1 className="font-lato_bold text-xl mt-4">Children’s Privacy</h1>
          <p className="my-2 font-lato_regular text-md">
            Our Service does not address anyone under the age of 18
            (“Children”).
          </p>
          <p className="my-2 font-lato_regular text-md">
            We do not knowingly collect personally identifiable information from
            children under 18. If you are a parent or guardian and you are aware
            that your child has provided us with Personal Information, please
            contact us. If we discover that a child under 18 has provided us
            with Personal Information, we will delete such information from our
            servers immediately.
          </p>

          <h1 className="font-lato_bold text-xl mt-4">Compliance With Laws</h1>
          <p className="my-2 font-lato_regular text-md">
            We will disclose your Personal Information where required to do so
            by law or subpoena.
          </p>

          <h1 className="font-lato_bold text-xl mt-4">
            Changes To This Privacy Policy
          </h1>
          <p className="my-2 font-lato_regular text-md">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p className="my-2 font-lato_regular text-md">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>

          <h1 className="font-lato_bold text-xl mt-4">Contact Us</h1>
          <p className="my-2 font-lato_regular text-md">
            If you have any questions about this Privacy Policy, please contact
            us at support@recozilla.com
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
