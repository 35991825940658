import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";
import mainLogo from "../assets/svgs/mainLogo.svg";
import PurpleLogo from "../assets/svgs/mainPurpleLogo.svg";

function Navbar({ backgroundColor = "#940DFF" }) {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };
  return (
    <div
      className={`text-white flex justify-between items-center h-[50px] md:h-[60px]  bg-[${backgroundColor}] mx-auto px-4 lg:px-[150px]`}
    >
      <Link to="/">
        <img src={mainLogo} className="w-[130px] md:w-[200px]" />
      </Link>
      <ul className="hidden md:flex">
        <li className="p-4 font-lato_semibold hover:scale-105 hover:cursor-pointer">
          <Link to="/about">About us</Link>
        </li>
        <li className="p-4 font-lato_semibold hover:scale-105 hover:cursor-pointer">
          <Link to="/contact">Contact us</Link>
        </li>
      </ul>

      <div onClick={handleNav} className="block md:hidden">
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      <div
        className={
          nav
            ? "fixed left-0 top-0 w-[60%] border-r border-r-gray-900 h-full bg-[#fff] text-black ease-in-out z-10 duration-500 md:hidden"
            : "fixed left-[-100%] ease-out z-10 duration-500"
        }
      >
        {/* <div className="bg-[#000000] h-10 rounded-[40px] mx-4 mt-20 flex items-center justify-center">
          <h1 className="text-white text-center">Login</h1>
        </div>
        <h1 className="my-4 text-center">OR</h1>
        <div className="bg-[#000000] h-10 rounded-[40px] mx-4  flex items-center justify-center">
          <h1 className="text-white text-center">Sign up</h1>
        </div> */}

        <ul className="mt-10 ">
          <li className="px-4 py-3 font-lato_regular hover:cursor-pointer">
            <Link to="/about">About us</Link>
          </li>
          <li className="px-4 py-3 font-lato_regular hover:cursor-pointer ">
            <Link to="/contact">Contact us</Link>
          </li>
          <li className="px-4 py-3 font-lato_regular  hover:cursor-pointer">
            <Link to="/privacy&policy">Privacy Policy</Link>
          </li>
          <li className="px-4 py-3  font-lato_regular hover:cursor-pointer">
            <Link to="/terms&conditions">Terms</Link>
          </li>
        </ul>

        <div className="mt-5 border-t-2">
          <Link to="/">
            {" "}
            <img src={PurpleLogo} className="px-4 mt-5" />
          </Link>
          <p className="font-light text-xs px-4 mt-2 text-gray-600 font-lato_light">
            2022 Recozilla. All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
