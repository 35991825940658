import React from "react";
import MainLogo from "../assets/svgs/mainLogo.svg";
import { AiOutlineInstagram } from "react-icons/ai";
import { CiTwitter } from "react-icons/ci";
import { FiFacebook } from "react-icons/fi";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="mx-auto py-5 px-4 grid lg:grid-cols-3 gap-8 text-gray-300 bg-black md:px-20 md:py-20">
      <div>
        <img src={MainLogo} className="w-[130px] md:w-[200px]" />
        <p className="py-4 text-xs md:text-lg font-light font-lato_regular">
          Our app is your go-to app for all things Q&A. You can ask questions,
          answer questions, vote for the best answers, and discover content
          about lots of topics. Join us today and be part of the Recozilla
          community! 💯
        </p>
      </div>
      <div className="lg:col-span-2 flex justify-between md:justify-around font-medium ">
        <div>
          <h1 className="text-sm mb-2 md:text-xl font-lato_bold">Company</h1>
          <ul>
            <Link
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              to="/about"
            >
              <li className="text-[12px] md:text-[14px] py-1 font-lato_light hover:text-[#BFFECD] hover:cursor-pointer">
                About Us
              </li>
            </Link>
            <Link
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              to="/contact"
            >
              <li className="text-[12px] md:text-[14px] py-1 font-lato_light hover:text-[#BFFECD] hover:cursor-pointer">
                Contact Us
              </li>
            </Link>
          </ul>
        </div>

        <div>
          <h1 className="text-sm mb-2 md:text-xl font-lato_bold">
            Legal Stuff
          </h1>
          <ul>
            <Link
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              to="/privacy&policy"
            >
              <li className="text-[12px] md:text-[14px] py-1 font-lato_light hover:text-[#BFFECD] hover:cursor-pointer">
                Privacy Policy
              </li>
            </Link>
            <Link
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              to="/terms&conditions"
            >
              <li className="text-[12px] md:text-[14px] py-1 font-lato_light hover:text-[#BFFECD] hover:cursor-pointer">
                Terms & Conditions
              </li>
            </Link>
          </ul>
        </div>

        <div>
          <h1 className="text-sm mb-2 md:text-xl font-lato_bold text-center">
            Follow Us
          </h1>
          <div className="flex justify-center ">
            <a
              href="https://www.instagram.com/joinrecozilla/"
              className="hover:cursor-pointer"
            >
              <AiOutlineInstagram
                size={22}
                color="#BFFECD"
                className=" hover:cursor-pointer"
                strokeWidth={1}
              />
            </a>
            <div className="w-[18px]" />
            <a
              href="https://twitter.com/JoinRecozilla"
              className="hover:cursor-pointer"
            >
              <CiTwitter
                size={22}
                color="#BFFECD"
                className="hover:cursor-pointer"
                strokeWidth={0.8}
              />
            </a>
          </div>
          <p className="font-light font-lato_light text-gray-300 text-[10px] md:hidden mt-2">
            2022 Recozilla. All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
